/* eslint-disable @typescript-eslint/ban-ts-comment */
import { usePreferredLanguage } from 'admin-portal-shared-services';
import React, { ReactNode, useEffect, useState } from 'react';
import { IntlProvider as NativeProvider } from 'react-intl';
import MessageMap from './i18n.d';
import LanguageContext from './LanguageContext';
import en from './languages/en';
import pt from './languages/pt';

const DEFAULT_LANGUAGE = 'pt-BR';
type IIntlProvider = {
  children: ReactNode;
};
const messages: { [language: string]: MessageMap } = {
  'en-US': en,
  'pt-BR': pt
};

const IntlProvider = ({ children }: IIntlProvider): JSX.Element => {
  const [language, setLanguage] = useState('en-US');

  const preferredLanguage = usePreferredLanguage();

  useEffect(() => setLanguage(preferredLanguage ?? 'en-US'), [preferredLanguage]);

  const contextValue = React.useMemo(() => ({ language, setLanguage }), [language, setLanguage]);

  const mergedMessages = mergeMessages(messages, language);

  return (
    <LanguageContext.Provider value={contextValue}>
      <NativeProvider locale={language} defaultLocale={language} messages={mergedMessages}>
        {children}
      </NativeProvider>
    </LanguageContext.Provider>
  );
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
export const flattenObject = (ob: any): any => {
  const toReturn: { [key: string]: any } = {};

  for (const i in ob) {
    if (typeof ob[i] === 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i]);
      // eslint-disable-next-line guard-for-in
      for (const x in flatObject) {
        toReturn[`${i}.${x}`] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};
/* eslint-enable */

export const mergeMessages = (messagesInput: MessageMap, selectedLocale: string): any => {
  const defaultMessages = flattenObject(messagesInput[DEFAULT_LANGUAGE]);
  const localeMessages = flattenObject(messagesInput[selectedLocale]);
  return { ...defaultMessages, ...localeMessages };
};

export default IntlProvider;
