import React, { lazy, Suspense } from 'react';
import { Route, Routes as Switch } from 'react-router-dom';
import Loading from '../components/Loading/Loading';
import {
  designDocumentationPage,
  getStartedPage,
  technicalDocumentationPage
} from '../data/sectionPages';
import { BASE_PATH } from '../utils/constants';
import DatePickerExamplePage from './DatePickerExamplePage/DatePickerExamplePage';
import SegmentedControlExamplePage from './SegmentedControlExamplePage/SegmentedControlExamplePage';
import SkeletonLoaderExamplePage from './SkeletonLoaderExamplePage/SkeletonLoaderExamplePage';

const OverviewPage = lazy(() => import('./OverviewPage/OverviewPage'));
const QuickStartPage = lazy(() => import('./QuickStartPage/QuickStartPage'));
const MainMFEsPage = lazy(() => import('./MainMFEsPage/MainMFEsPage'));
const TrackingMonitoringPage = lazy(
  () => import('./TrackingMonitoringPage/TrackingMonitoringPage')
);
const ErrorHandlingPage = lazy(() => import('./ErrorHandlingPage/ErrorHandlingPage'));
const OmniWrapperPage = lazy(() => import('./OmniWrapperPage/OmniWrapperPage'));
const TermsOfServicePage = lazy(() => import('./TermsOfServicePage/TermsOfServicePage'));
const FormExamplePage = lazy(() => import('./formExamplePage/FormExamplePage'));
const TooltipExamplePage = lazy(() => import('./TooltipExamplePage/TooltipExamplePage'));
const BrandExamplePage = lazy(() => import('./BrandExamplePage/BrandExamplePage'));
const TypographyExamplePage = lazy(() => import('./TypographyExamplePage/TypographyExamplePage'));
const TextButtonExamplePage = lazy(() => import('./TextButtonExamplePage/TextButtonExamplePage'));
const ListItemExamplePage = lazy(() => import('./ListItemExamplePage/ListItemExamplePage'));
const TextLinkExamplePage = lazy(() => import('./TextLinkExamplePage/TextLinkExamplePage'));
const ToggleExamplePage = lazy(() => import('./ToggleExamplePage/ToggleExamplePage'));
const IconButtonExamplePage = lazy(() => import('./IconButtonExamplePage/IconButtonExamplePage'));
const AlertExamplePage = lazy(() => import('./AlertExamplePage/AlertExamplePage'));
const ProgressMeterExamplePage = lazy(() => import('./ProgressMeterExample/ProgressMeterExample'));
const PaginationExamplePage = lazy(() => import('./PaginationExamplePage/PaginationExamplePage'));
const DividerExamplePage = lazy(() => import('./DividerExamplePage/DividerExamplePage'));
const SearchFieldExamplePage = lazy(
  () => import('./SearchFieldExamplePage/SearchFieldExamplePage')
);
const DialogExamplePage = lazy(() => import('./DialogExamplePage/DialogExamplePage'));
const TableExamplePage = lazy(() => import('./TableExamplePage/TableExamplePage'));
const ButtonExamplePage = lazy(() => import('./ButtonExamplePage/ButtonExamplePage'));
const SelectExamplePage = lazy(() => import('./SelectExamplePage/SelectExamplePage'));
const ComboboxExamplePage = lazy(() => import('./ComboboxExamplePage/ComboboxExamplePage'));
const UseUserMetadataPage = lazy(() => import('./useUserMetadata'));
const UseCountryServicePage = lazy(() => import('./useCountryService'));
const UseFeatureTogglePage = lazy(() => import('./useFeatureToggle'));
const BreadcrumbExamplePage = lazy(() => import('./BreadcrumbExamplePage/BreadcrumbExamplePage'));
const ToastExamplePage = lazy(() => import('./ToastExamplePage/ToastExamplePage'));
const ModalExamplePage = lazy(() => import('./ModalExamplePage/ModalExamplePage'));
const AvatarExamplePage = lazy(() => import('./AvatarExamplePage/AvatarExamplePage'));
const IconsExamplePage = lazy(() => import('./IconsExamplePage/IconsExamplePage'));
const HeaderConfigExamplePage = lazy(
  () => import('./HeaderConfigExamplePage/HeaderConfigExamplePage')
);
const SpinnerExamplePage = lazy(() => import('./SpinnerExamplePage/SpinnerExamplePage'));
const PermissionsExamplePage = lazy(
  () => import('./PermissionsExamplePage/PermissionsExamplePage')
);
const ExamplesPage = lazy(() => import('./ExamplesPage/ExamplesPage'));
const ClusterServicesExamplePage = lazy(
  () => import('./ClusterServicesExamplePage/ClusterServicesExamplePage')
);
const ProgressTrackerExamplePage = lazy(
  () => import('./ProgressTrackerExamplePage/ProgressTrackerExamplePage')
);
const CrossImportsExamplePage = lazy(
  () => import('./CrossImportsExamplePage/CrossImportsExamplePage')
);
const CalendarExamplePage = lazy(() => import('./CalendarExamplePage/CalendarExamplePage'));
const SnackbarExamplePage = lazy(() => import('./SnackbarExamplePage/SnackbarExamplePage'));
const ChipsExamplePage = lazy(() => import('./ChipsExamplePage/ChipsExamplePage'));
const FileUploaderExamplePage = lazy(
  () => import('./FileUploaderExamplePage/FileUploaderExamplePage')
);
const FileMultiUploaderExamplePage = lazy(
  () => import('./FileMultiUploaderExamplePage/FileMultiUploaderExamplePage')
);
const EmptyStateExamplePage = lazy(() => import('./EmptyStateExamplePage/EmptyStateExamplePage'));
const ImageExamplePage = lazy(() => import('./ImageExamplePage/ImageExamplePage'));
const ExpandableSectionPage = lazy(() => import('./ExpandableSectionPage/ExpandableSectionPage'));
const QuantifierExamplePage = lazy(() => import('./QuantifierExamplePage/QuantifierExamplePage'));
const BadgeExamplePage = lazy(() => import('./BadgeExamplePage/BadgeExamplePage'));
const CarouselExamplePage = lazy(() => import('./CarouselExamplePage/CarouselExamplePage'));

const DlbExamplePage = lazy(() => import('./DlbExamplePage/DlbExamplePage'));
const AudienceCreatorExamplePage = lazy(
  () => import('./AudienceCreatorExamplePage/AudienceCreatorExamplePage')
);

const SectionPage = lazy(() => import('../components/SectionPage'));
const HexaTokensPage = lazy(() => import('./HexaTokensPage/HexaTokensPage'));
const ToolNamingPage = lazy(() => import('./ToolNamingPage/ToolNamingPage'));
const DesignPatternsPage = lazy(() => import('./DesignPatternsPage/DesignPatternsPage'));

export const Routes = (): JSX.Element => (
  <Suspense fallback={<Loading />}>
    <Switch>
      <Route path={`${BASE_PATH}`} element={<OverviewPage />} />
      <Route path={`${BASE_PATH}/get-started`} element={<SectionPage props={getStartedPage} />} />
      <Route
        path={`${BASE_PATH}/technical-documentation`}
        element={<SectionPage props={technicalDocumentationPage} />}
      />
      <Route
        path={`${BASE_PATH}/design-documentation`}
        element={<SectionPage props={designDocumentationPage} />}
      />
      <Route path={`${BASE_PATH}/design-documentation/hexa-tokens`} element={<HexaTokensPage />} />
      <Route path={`${BASE_PATH}/design-documentation/tool-naming`} element={<ToolNamingPage />} />
      <Route
        path={`${BASE_PATH}/design-documentation/design-patterns`}
        element={<DesignPatternsPage />}
      />
      <Route path={`${BASE_PATH}/quick-start`} element={<QuickStartPage />} />
      <Route path={`${BASE_PATH}/mfes-components`} element={<MainMFEsPage />} />
      <Route path={`${BASE_PATH}/tracking-monitoring`} element={<TrackingMonitoringPage />} />
      <Route path={`${BASE_PATH}/error-handling`} element={<ErrorHandlingPage />} />
      <Route path={`${BASE_PATH}/expandable-section`} element={<ExpandableSectionPage />} />
      <Route path={`${BASE_PATH}/omni-wrapper`} element={<OmniWrapperPage />} />
      <Route path={`${BASE_PATH}/terms-of-service`} element={<TermsOfServicePage />} />
      <Route path={`${BASE_PATH}/examples/form`} element={<FormExamplePage />} />
      <Route path={`${BASE_PATH}/examples/tooltip`} element={<TooltipExamplePage />} />
      <Route path={`${BASE_PATH}/examples/brand`} element={<BrandExamplePage />} />
      <Route path={`${BASE_PATH}/examples/typography`} element={<TypographyExamplePage />} />
      <Route path={`${BASE_PATH}/examples/text-button`} element={<TextButtonExamplePage />} />
      <Route path={`${BASE_PATH}/examples/text-link`} element={<TextLinkExamplePage />} />
      <Route path={`${BASE_PATH}/examples/toggle`} element={<ToggleExamplePage />} />
      <Route path={`${BASE_PATH}/examples/icon-button`} element={<IconButtonExamplePage />} />
      <Route path={`${BASE_PATH}/examples/alert`} element={<AlertExamplePage />} />
      <Route path={`${BASE_PATH}/examples/pagination`} element={<PaginationExamplePage />} />
      <Route path={`${BASE_PATH}/examples/progress-meter`} element={<ProgressMeterExamplePage />} />
      <Route path={`${BASE_PATH}/examples/search-field`} element={<SearchFieldExamplePage />} />
      <Route path={`${BASE_PATH}/examples/dialog`} element={<DialogExamplePage />} />
      <Route path={`${BASE_PATH}/examples/table`} element={<TableExamplePage />} />
      <Route path={`${BASE_PATH}/examples/button`} element={<ButtonExamplePage />} />
      <Route path={`${BASE_PATH}/examples/select`} element={<SelectExamplePage />} />
      <Route path={`${BASE_PATH}/examples/combobox`} element={<ComboboxExamplePage />} />
      <Route path={`${BASE_PATH}/examples/useUserMetadata`} element={<UseUserMetadataPage />} />
      <Route path={`${BASE_PATH}/examples/useCountryService`} element={<UseCountryServicePage />} />
      <Route path={`${BASE_PATH}/examples/useFeatureToggle`} element={<UseFeatureTogglePage />} />
      <Route path={`${BASE_PATH}/examples/breadcrumb`} element={<BreadcrumbExamplePage />} />
      <Route path={`${BASE_PATH}/examples/toast`} element={<ToastExamplePage />} />
      <Route path={`${BASE_PATH}/examples/modal`} element={<ModalExamplePage />} />
      <Route path={`${BASE_PATH}/examples/avatar`} element={<AvatarExamplePage />} />
      <Route path={`${BASE_PATH}/examples/icons`} element={<IconsExamplePage />} />
      <Route path={`${BASE_PATH}/examples/list-item`} element={<ListItemExamplePage />} />
      <Route path={`${BASE_PATH}/examples/divider`} element={<DividerExamplePage />} />
      <Route
        path={`${BASE_PATH}/examples/segmented-control`}
        element={<SegmentedControlExamplePage />}
      />
      <Route path={`${BASE_PATH}/examples/header-config`} element={<HeaderConfigExamplePage />} />
      <Route path={`${BASE_PATH}/examples/spinner`} element={<SpinnerExamplePage />} />
      <Route
        path={`${BASE_PATH}/examples/skeleton-loader`}
        element={<SkeletonLoaderExamplePage />}
      />
      <Route path={`${BASE_PATH}/examples/permissions`} element={<PermissionsExamplePage />} />
      <Route path={`${BASE_PATH}/examples`} element={<ExamplesPage />} />
      <Route
        path={`${BASE_PATH}/examples/cluster-services`}
        element={<ClusterServicesExamplePage />}
      />
      <Route
        path={`${BASE_PATH}/examples/progress-tracker`}
        element={<ProgressTrackerExamplePage />}
      />
      <Route path={`${BASE_PATH}/examples/calendar`} element={<CalendarExamplePage />} />
      <Route path={`${BASE_PATH}/examples/cross-imports`} element={<CrossImportsExamplePage />} />
      <Route path={`${BASE_PATH}/examples/snackbar`} element={<SnackbarExamplePage />} />
      <Route path={`${BASE_PATH}/examples/chips`} element={<ChipsExamplePage />} />
      <Route path={`${BASE_PATH}/examples/file-uploader`} element={<FileUploaderExamplePage />} />
      <Route
        path={`${BASE_PATH}/examples/file-multi-uploader`}
        element={<FileMultiUploaderExamplePage />}
      />
      <Route path={`${BASE_PATH}/examples/empty-state`} element={<EmptyStateExamplePage />} />
      <Route path={`${BASE_PATH}/examples/image`} element={<ImageExamplePage />} />
      <Route
        path={`${BASE_PATH}/examples/expandable-section`}
        element={<ExpandableSectionPage />}
      />
      <Route path={`${BASE_PATH}/examples/quantifier`} element={<QuantifierExamplePage />} />
      <Route path={`${BASE_PATH}/examples/badge`} element={<BadgeExamplePage />} />
      <Route path={`${BASE_PATH}/examples/date-picker`} element={<DatePickerExamplePage />} />
      <Route path={`${BASE_PATH}/examples/carousel`} element={<CarouselExamplePage />} />
      <Route path={`${BASE_PATH}/dlb`} element={<DlbExamplePage />} />
      <Route path={`${BASE_PATH}/dlb/audience-creator`} element={<AudienceCreatorExamplePage />} />
      <Route path="/" element={<OverviewPage />} />
    </Switch>
  </Suspense>
);

export default Switch;
