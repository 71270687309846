import { Grid, SkeletonLoader } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const GridItem = styled(Grid.Item, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '&:nth-child(n + 2)': {
    marginTop: '$4'
  },
  '& h1': {
    marginBottom: '$4'
  }
});

export const SkeletonLoaderContent = styled('div', {
  display: 'flex',
  flexDirection: 'column'
});

export const StyledLoaderExampleInputsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'center',
  marginBottom: '$6',
  '& div:first-child': {
    marginRight: '$4'
  }
});

export const StyledSkeletonLoaderContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'center'
});

export const StyledSkeletonLoader = styled(SkeletonLoader, {
  '& + &': {
    marginLeft: '$4'
  }
});

export const SkeletonLoaderExampleContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '& + &': {
    marginTop: '$4'
  },
  '& > h1': {
    marginBottom: '$2'
  }
});
