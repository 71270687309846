import { createContextProvider } from '@hexa-ui/components';
import { ThemeProviderType } from '@hexa-ui/theme';

export type SetTheme = {
  setTheme: React.Dispatch<React.SetStateAction<ThemeProviderType['theme']>>;
};

const initialValues = {
  setTheme: () => {
    return undefined;
  }
};

const [SetThemeProvider, useSetThemeProvider] = createContextProvider<SetTheme>(
  'SetThemeProvider',
  initialValues
);

export { SetThemeProvider, useSetThemeProvider };
