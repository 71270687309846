import { UploadableFile } from '@hexa-ui/components';
import React from 'react';

type FileContextType = {
  files: UploadableFile[];
  setFiles: (files: UploadableFile[]) => void;
};

export const FileContext = React.createContext<FileContextType>({
  files: [],
  setFiles: () => {}
});

export const FileProvider = ({ children }: { children: React.ReactNode }) => {
  const initialFiles = () => {
    const savedFiles = localStorage.getItem('files');
    return savedFiles ? JSON.parse(savedFiles) : [];
  };

  const [files, setFiles] = React.useState<UploadableFile[]>(initialFiles);

  const handleSetFiles = (newFiles: UploadableFile[]) => {
    setFiles(newFiles);
    localStorage.setItem('files', JSON.stringify(newFiles));
  };

  return (
    <FileContext.Provider value={{ files, setFiles: handleSetFiles }}>
      {children}
    </FileContext.Provider>
  );
};
