/* eslint-disable no-alert */
import { Heading, SegmentedControl, SegmentedControlOptions } from '@hexa-ui/components';
import { useAppHeader } from 'admin-portal-shared-services';
import React, { useEffect } from 'react';
import {
  SegmentedControlContent,
  SegmentedControlExampleContent
} from './SegmentedControlExamplePage.styles';

const OPTIONS: SegmentedControlOptions[] = [
  {
    label: 'Label 1',
    value: '1'
  },
  {
    label: 'Label 2',
    value: '2'
  },
  {
    label: 'Label 3',
    value: '3'
  }
];

const OPTIONS_WITH_LONG_LABEL = [
  {
    label: 'A very long label example',
    value: '1'
  },
  {
    label: 'A very long label example',
    value: '2'
  },
  {
    label: 'A very long label example',
    value: '3'
  }
];

const SegmentedControlExamplePage = (): JSX.Element => {
  const [, setAppHeaderConfig] = useAppHeader();

  useEffect(() => {
    setAppHeaderConfig({
      pageTitle: 'Segmented Control',
      breadcrumbConfig: {
        homePath: '/overview',
        items: [
          {
            label: 'Examples',
            path: '/overview/examples',
            isCurrentPage: false
          },
          {
            label: 'Segmented Control',
            path: '/overview/examples/segmented-control',
            isCurrentPage: true
          }
        ]
      },
      optionalButtons: []
    });
  }, [setAppHeaderConfig]);

  return (
    <SegmentedControlContent>
      <SegmentedControlExampleContent>
        <Heading alignment="center">With two options</Heading>
        <SegmentedControl options={OPTIONS.slice(0, -1)} />
      </SegmentedControlExampleContent>
      <SegmentedControlExampleContent>
        <Heading alignment="center">With three options</Heading>
        <SegmentedControl options={OPTIONS} />
      </SegmentedControlExampleContent>
      <SegmentedControlExampleContent>
        <Heading alignment="center">With long labels</Heading>
        <SegmentedControl options={OPTIONS_WITH_LONG_LABEL} />
      </SegmentedControlExampleContent>
      <SegmentedControlExampleContent>
        <Heading alignment="center">With onValueChange event</Heading>
        <SegmentedControl
          options={OPTIONS}
          onValueChange={(value) => alert(`Option ${value} clicked`)}
        />
      </SegmentedControlExampleContent>
      <SegmentedControlExampleContent>
        <Heading alignment="center">With custom default selected value</Heading>
        <SegmentedControl defaultValue="2" options={OPTIONS} />
      </SegmentedControlExampleContent>
    </SegmentedControlContent>
  );
};

export default SegmentedControlExamplePage;
