import {
  AppGrid,
  BarChart2,
  Check,
  Checklist1,
  Checklist2,
  Clipboard,
  DocumentCheck,
  DocumentSigned,
  MessageCircle,
  Share,
  Web
} from '@hexa-ui/icons2';
import { Section } from '../models/SectionPage';
import { BASE_PATH } from '../utils/constants';

export const getStartedPage: Section = {
  pageTitle: 'Get started',
  path: `${BASE_PATH}/get-started`,
  description:
    'This content helps you to align your work with BEES One purposes and standards. Whether you are developing a tool or feature, exploring the platform and its last releases since the beginning of your project will bring you valuable insights.',
  navigationItems: [
    {
      title: 'Shared features',
      icon: Share,
      description: 'Explore how the current features can enhance your work.'
    },
    {
      title: 'Compliance checklist',
      icon: AppGrid,
      description: "Evaluate if the tool or feature fits the platform's purpose."
    },
    {
      title: 'Coming next',
      icon: MessageCircle,
      description: 'Stay tuned for the releases and the next developments.'
    }
  ]
};

export const technicalDocumentationPage: Section = {
  pageTitle: 'Technical documentation',
  path: `${BASE_PATH}/technical-documentation`,
  description:
    'The technical documentation provides sources and content for developing high-quality tools and features. It includes useful information for your ongoing project, from implementing the design system and micro-front-ends to establishing quality and approval processes.',
  navigationItems: [
    {
      title: 'Hexa implementation',
      icon: Web,
      description: 'Access the parameters to implement reusable components.'
    },
    {
      title: 'Micro-front-end implementation',
      icon: DocumentSigned,
      description: 'Find recommendations and KPIs for your micro-front-ends.'
    },
    {
      title: 'Quality and performance standards',
      icon: DocumentCheck,
      description: 'Discover best practices to meet the expected standards.'
    },
    {
      title: 'Approval process',
      icon: Check,
      description: 'Comprehend the approval steps before submitting your project.'
    },
    {
      title: 'Data tracking',
      icon: BarChart2,
      description: 'Use standard segment tracking for effective tool management.'
    },
    {
      title: 'Glossary',
      icon: Clipboard,
      description: 'Learn the main terms, from tech jargon to team expressions.'
    }
  ]
};

export const designDocumentationPage: Section = {
  pageTitle: 'Design documentation',
  path: `${BASE_PATH}/design-documentation`,
  description:
    'The design documentation provides instructions and best practices for building a tool or feature that is compliant with the BEES One guidelines, ensuring a consistent and efficient user experience.',
  navigationItems: [
    {
      title: 'Hexa tokens and templates',
      icon: Web,
      description: "Understand the ABI's design system and its components.",
      route: `${BASE_PATH}/design-documentation/hexa-tokens`
    },
    {
      title: 'Tool naming',
      icon: Checklist2,
      description: 'Check the guidelines to define names for new tools.',
      route: 'tool-naming'
    },
    {
      title: 'Design pattern examples',
      icon: Checklist1,
      description: 'Get inspired by examples of applied components.',
      route: 'design-patterns'
    }
  ]
};
