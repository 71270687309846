import { Grid } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const SegmentedControlContent = styled('div', {
  margin: 'auto'
});

export const SegmentedControlExampleContent = styled(Grid.Item, {
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '& + &': {
    marginTop: '$4'
  },
  '& > h1': {
    marginBottom: '$2'
  }
});
