import MessageMap from '../i18n.d';

const en: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Just English option available'
  },
  app: {
    close: 'Close',
    overview: 'Overview',
    quick_start: 'Quick Start',
    main_mfes: 'Main MFEs and Comp...',
    tracking_monitoring: 'Tracking and Monitoring',
    error_handling: 'Error Handling',
    omni_wrapper: 'Omni Wrapper'
  },
  sidebar: {
    overview: 'Overview',
    quick_start: 'Quick Start',
    mfe_components: 'MFES Components',
    tracking_monitoring: 'Tracking Monitoring',
    error_handling: 'Error Handling',
    omni_wrapper: 'Omni Wrapper',
    examples: 'Examples',
    dlb_shared_business_components: 'DLB Shared Business Components Examples',
    terms_of_service: 'Terms of Service',
    documentation_overview: 'Documentation Overview',
    privacy: 'Privacy'
  },
  welcome: {
    title: 'Welcome to BEES One',
    tab: 'Welcome',
    what_is_bees_hub: 'What is the BEES One?',
    what_is_bees_hub_description:
      '<p>The BEES One is an application ecosystem that allows development teams to build and deploy their applications individually, while providing a simple container to bring all applications together into a unified experience.</p><p>We want to provide for all developers a safe and flexible environment to create great applications.</p>',
    what_is_the_bees_hub_framework: 'What is the BEES One Framework?',
    what_is_the_bees_hub_framework_description:
      '<p>The BEES One Framework (BOF) is a set of tools, standards and ready-to-use code components that helps the developers to focus on the business requirements while building new applications, either inside BEES One or in separated portals.</p><p>The BEES One by itself is built using the BOF.</p>',
    know_our_dream_team: 'Know our dream team!',
    applications_in_production: 'More than 40 applications in production',
    how_to_get_access_to_bees_hub: 'How to get access to BEES One?',
    how_to_get_access_step_1:
      'To access lower environments, you will need to ask for access on the slack channel #bees-hub-general, sending the required information listed below.',
    click_here: 'Click here to see the information required',
    how_to_get_access_step_2:
      'As soon as the access is granted, you will receive a notification in your e-mail.',
    how_to_get_access_prod:
      'For PROD access, you will need to open a ticket for each application. Do not forget to send all the required information.',
    required_information: 'Required information',
    open_ticket: 'Open ticket',
    us_bees_hub: 'US BEES One',
    us_bees_hub_description:
      '<p>A totally segregated instance of BEES One in running to attend all requirements of data separation for US.</p><p>Only non-ABI professionals can have access to that.</p><p>Please contact us in case of questions.</p>',
    service_request: {
      user_email: "User's E-mail",
      environment: 'Environment: Dev/SIT/UAT',
      country: 'Country (main country of that user)',
      list_country: "List of Countries (if it's a requirement of the application)",
      authorization_group: 'Authorization group'
    }
  },
  frontend_features: {
    tab: 'Frontend Features',
    components: {
      title: 'Which components can I use?',
      description: 'Know our Storybook and check all our frontend components available:',
      storybook: 'Link to our Storybook',
      login_info: 'Login information:',
      user: 'User: ',
      password: 'Password: '
    },
    language: {
      title: "Let's change the Application Language?",
      description: 'You can do it by going on user profile page under user preferences:',
      change_language: "Or you can add your own component. Let's try with flags:"
    },
    auth: {
      title: 'Authentication and Authorization',
      description: 'We are fully integrated with ',
      identity_solutions: 'Identity Solutions',
      authentication: 'Authentication:',
      azure: 'Azure B2C Login, Registration, Password Reset',
      authorization: 'Authorization:',
      casbin:
        'Based on Casbin Framework, allows devs to have fine control of permissions in their applications'
    }
  },
  quick_start: {
    title: 'Quick Start',
    description: 'Start developing your MFE in a few steps',
    mfe_generator: {
      title: 'MFE Generator',
      buttonTitle: 'Create a new MFE to integrate on BEES One',
      description:
        'We have created a MicroFrontEnd Generator, allowing you to create your first MFE in a few steps. All details can be found in our Confluence Page:',
      conclusion:
        'At the end of the tutorial you will have a new MFE based on BEES One Framework ready to be used in your business application.'
    },
    deployment: {
      title: 'Deployment',
      description:
        'To help in the first steps of deployment for a new MFE inside BEES One and going further, implementing pipelines in compliance with ABI standards, follow the links below:',
      first_steps_button: 'Deploy - First Steps',
      golden_pipeline_button: 'Golden Pipeline V2'
    },
    code_examples: {
      title: 'Code examples',
      description:
        'Want to get some code examples? In addition to our confluence content, all the implementation code for this MFE can be found at:',
      repo_button: 'Overview MFE Repository'
    }
  },
  main_mfes_components: {
    title: 'Our MFEs and components',
    description: 'Know what is available for all applications',
    top_menu: {
      title: 'Top Menu',
      description:
        'The top menu is an MFE built by the BEES One team and can be customized for each page'
    },
    logo: {
      title: 'Logo',
      description:
        'You can add a custom logo by flavors. For create a new flavor, you need to contact the design team for add a new theme',
      knowMore: 'See more in Theme configuration Page'
    },
    sidebar_menu: {
      title: 'Sidebar',
      description: 'The developer can add different pages, modules and sections to his appication',
      hookDocumentation: 'useSidebar hook documentation page',
      aboutHook:
        'As of version 2.1.1 of the admin-portal-shared-services library, the useSidebar hook is available which MFE can use to configure its menu items',
      step_1: 'Using the "sidebar" the portal will show a it with customizable items.',
      step_2:
        'Click the icon in the upper left corner of the page and check the behavior of this component on mobile, for larger screens, you can see the sidebar items on the left of the screen.'
    },
    fixed_links: {
      title: 'Fixed Links',
      description:
        'The BEES One provides a set of menus ready to use based in the configurations of each MFE',
      link_help: 'Get Help',
      link_privacy: 'Privacy Policy',
      link_terms: 'Terms of Service',
      step: 'Check how would work these items by click in your Avatar'
    },
    app_switcher: {
      title: 'App Switcher',
      description: 'This component will show all the applications that the logged user can access'
    },
    language_selector: {
      title: 'Language Selector',
      knowMorePreferredLanguage: 'usePreferredLanguageV2 hook documentation page',
      aboutDefaultLanguageSelection:
        'System languages can be changed on user profile page under user preferences. For the MFE to get the language selected by this page, we provide the preferredLanguage hook that returns the language selected by the user on this screen.',
      aboutCustonLanguageSelection:
        'The developer can configure the support for different languages in his MFE in a customized way if he wants like with customized components below for example'
    }
  },
  error_handling: {
    title: 'Error and Warning Pages',
    subtitle: 'With standard pages we can communicate with the user clearly and consistently',
    user_without_access: 'User without access to any application',
    page_not_found: 'Page Not Found',
    generic_error: 'Generic Error'
  },
  tracking: {
    title: 'Tracking and Monitoring',
    subtitle: 'Follow everything that happens with your application',
    description:
      'The BEES One Framework provides to the developer a set of pre-integrated tools to track user behavior and monitoring the applications health.',
    new_relic:
      'The BEES One instances are monitored by New Relic, using the integration created with our Framework. By setting the custom attribute mfeName in the logs and events the application can use the filtering mechanism and generate a per-mfe telemetry',
    check_our_dashboards: 'Check our Dashboards',
    more_details: 'More Details',
    optimizely:
      '<p>Optimizely feature flags can be used for controlling feature status, rollout percentages and country targets.</p><p>Get you Project Key and start to using our library with bulti-in integration</p>',
    segment:
      '<p>Track the user behavior since the landing page, going through authentication and inside each application.</p><p>With persistent AnonymousID we can track all user flow aligned with SDI requirements and reduced costs.</p>'
  },
  omniwrapper: {
    title: 'Omni Wrapper',
    subtitle: 'One wrapper to rule them all',
    description:
      '<p>The Omni Wrapper is a microfrontend orchestration application for managing application routing, layout and shared dependencies.</p><p>It enables fast bootstrapping for new frontend applications independently with near zero cross-team dependencies.</p>',
    motivation: {
      title: 'Motivation',
      description: `<p>Consider only the BEES One, there are currently <bold>14 value streams (and growing)</bold> that may require applications within the BEES One. Each team will have differing opinions about coding standards and patterns. Maintaining a unified toolchain, practices and standards is an impossible task.</p><p>Our current architecture has soft boundaries between applications making it difficult for developers to make changes confidently. Business logic crosses applications and even lives within the current wrapper. Developers cannot effectively test some changes because dependent logic lives in applications they are unable to run locally. The resulting microfrontend architecture fails to leverage the main benefits that it provides.</p><p>The Omni Wrapper solves these problems with a focused Architecture with clear boundaries. We use the <a>Layout Engine</a> from the latest version of Single-spa to achieve this.</p>`
    },
    key_benefits: {
      title: 'Key benefits',
      benefits: {
        use_your_toolchain: {
          placeholder: 'Use your toolchain',
          tooltip:
            'Omni Wrapper is built on single-spa, giving teams autonomy in framework, tooling and deploy cadence.'
        },
        create_new_applications: {
          placeholder: 'Create new applications with zero backend considerations',
          tooltip:
            'Omni Wrapper provides everything needed for deploying frontend applications. Develop, deploy, reload the page.'
        },
        test_changes: {
          placeholder: 'Test changes against any environment',
          tooltip:
            'Automation and QA can easily test changes in-browser before they are promoted to new environments.'
        },
        develop_locally: {
          placeholder: 'Develop locally against any environment',
          tooltip:
            'Any Omni Wrapper instance can be used for local development. Developers no longer need local workarounds and mocks for services, authentication, etc. The added consistency reduces bugs due to differences between local and hosted environments.'
        },
        prevent_monolithic: {
          placeholder: 'Prevent monolithic creep',
          tooltip: `Omni wrapper is a single-spa orchestrator, that's all. This forces new logic and services outside of the wrapper application.`
        }
      },
      check_confluence_button: 'Check our Confluence page for more details'
    }
  },
  terms_of_service: {
    title: 'Terms Of Service',
    description: 'You can redirect your user to a new page, inside or out of BEES One!'
  },
  get_help: {
    title: 'Get Help',
    description:
      'Need any additional help? Contact us in the Slack channel <bold>#admin-portal-general</bold>.'
  },
  examples: {
    title: 'Examples',
    description:
      'We use overview to implement some components and features. Check the available examples!'
  },
  dlb_examples: {
    title: 'DLB shared business components examples',
    description:
      'We use overview to implement some components and features of the DLB shared business components lib. Check the available examples!'
  }
};

export default en;
