import { Heading, Input, SkeletonLoader } from '@hexa-ui/components';
import { useAppHeader } from 'admin-portal-shared-services';
import React, { useEffect, useState } from 'react';
import {
  GridItem,
  StyledLoaderExampleInputsContainer,
  StyledSkeletonLoader,
  StyledSkeletonLoaderContainer
} from './SkeletonLoaderExamplePage.styles';

const SkeletonLoaderExamplePage = (): JSX.Element => {
  const [width, setWidth] = useState<string>('220px');
  const [height, setHeight] = useState<string>('200px');
  const [, setAppHeaderConfig] = useAppHeader();

  useEffect(() => {
    setAppHeaderConfig({
      pageTitle: 'Skeleton Loader',
      breadcrumbConfig: {
        homePath: '/overview',
        items: [
          {
            label: 'Examples',
            path: '/overview/examples',
            isCurrentPage: false
          },
          {
            label: 'Skeleton Loader',
            path: '/overview/examples/skeleton-loader',
            isCurrentPage: true
          }
        ]
      },
      optionalButtons: []
    });
  }, [setAppHeaderConfig]);

  return (
    <>
      <GridItem xs={12}>
        <Heading alignment="center">Body Variant</Heading>
        <SkeletonLoader variant="body" width="400px" />
      </GridItem>

      <GridItem xs={12}>
        <Heading alignment="center">Image Variant</Heading>
        <SkeletonLoader variant="image" width="250px" height="200px" />
      </GridItem>

      <GridItem xs={12}>
        <Heading alignment="center">Cicle Image</Heading>
        <SkeletonLoader variant="image" imageShape="circle" width="200px" height="200px" />
      </GridItem>

      <GridItem xs={12}>
        <Heading alignment="center">With custom width and height</Heading>
        <StyledLoaderExampleInputsContainer>
          <Input
            type="string"
            value={width}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setWidth(e.target.value)}
            label="Width(px, rem, %, ...)"
          />
          <Input
            type="string"
            value={height}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHeight(e.target.value)}
            label="Heigth(px, rem, %, ...)"
          />
        </StyledLoaderExampleInputsContainer>
        <StyledSkeletonLoaderContainer>
          <StyledSkeletonLoader variant="body" width={width} height={height} />
          <StyledSkeletonLoader variant="image" width={width} height={height} />
        </StyledSkeletonLoaderContainer>
      </GridItem>
    </>
  );
};

export default SkeletonLoaderExamplePage;
