import { createContextProvider } from '@hexa-ui/components';

export type VendorIdFromEnv = {
  BR: string;
  MX: string;
  CO: string;
  PE: string;
  EC: string;
};

export type Env = {
  env: {
    segmentKey: string;
    vendorId: VendorIdFromEnv;
  };
};

const initialValues = {
  env: {
    segmentKey: '',
    vendorId: {
      BR: '',
      MX: '',
      CO: '',
      PE: '',
      EC: ''
    }
  }
};

const [EnvProvider, useEnvProvider] = createContextProvider<Env>('EnvProvider', initialValues);

export { EnvProvider, useEnvProvider };
