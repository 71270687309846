import { DatePicker, Grid, Heading } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import { useAppHeader } from 'admin-portal-shared-services';
import * as React from 'react';

const DatePickerExamplePage = (): JSX.Element => {
  const [, setAppHeaderConfig] = useAppHeader();
  const [singleDate, setSingleDate] = React.useState<Date[]>();
  const [rangeDate, setRangeDate] = React.useState<Date[]>();
  const [singleDateCTA, setSingleDateCTA] = React.useState<Date[]>();
  const [rangeDateCTA, setRangeDateCTA] = React.useState<Date[]>();
  const [singleDateDouble, setSingleDateDouble] = React.useState<Date[]>();
  const [singleDateLegend, setSingleDateLegend] = React.useState<Date[]>();
  const [singleDateLegendCTA, setSingleDateLegendCTA] = React.useState<Date[]>();
  const [withLabel, setWithLabel] = React.useState<Date[]>();
  const [withError, setWithError] = React.useState<Date[]>();

  React.useEffect(() => {
    setAppHeaderConfig({
      pageTitle: 'DatePicker',
      breadcrumbConfig: {
        homePath: '/overview',
        items: [
          {
            label: 'Examples',
            path: '/overview/examples',
            isCurrentPage: false
          },
          {
            label: 'DatePicker',
            path: '/overview/examples/DatePicker',
            isCurrentPage: true
          }
        ]
      },
      optionalButtons: []
    });
  }, [setAppHeaderConfig]);

  return (
    <>
      <StyledGridItem xs={12} sm={3}>
        <Heading css={{ marginBottom: '$4' }} size="H4">
          Single selection
        </Heading>
        <DatePicker value={singleDate} onChange={setSingleDate} customLocale="pt" />
      </StyledGridItem>
      <StyledGridItem xs={12} sm={3}>
        <Heading css={{ marginBottom: '$4' }} size="H4">
          Range selection
        </Heading>
        <DatePicker
          value={rangeDate}
          onChange={setRangeDate}
          numberOfMonths={2}
          mode="range"
          width="300px"
          customLocale="pt"
        />
      </StyledGridItem>
      <StyledGridItem xs={12} sm={3}>
        <Heading css={{ marginBottom: '$4' }} size="H4">
          Single selection with CTA
        </Heading>
        <DatePicker
          value={singleDateCTA}
          onChange={setSingleDateCTA}
          onSave={setSingleDateCTA}
          callToActionSection
          customLocale="pt"
        />
      </StyledGridItem>
      <StyledGridItem xs={12} sm={3}>
        <Heading css={{ marginBottom: '$4' }} size="H4">
          Range selection with CTA
        </Heading>
        <DatePicker
          value={rangeDateCTA}
          onChange={setRangeDateCTA}
          onSave={setRangeDateCTA}
          callToActionSection
          mode="range"
          numberOfMonths={2}
          width="300px"
          align="right"
          customLocale="pt"
        />
      </StyledGridItem>
      <StyledGridItem xs={12} sm={3}>
        <Heading css={{ marginBottom: '$4' }} size="H4">
          Single selection with 2 months
        </Heading>
        <DatePicker
          value={singleDateDouble}
          onChange={setSingleDateDouble}
          numberOfMonths={2}
          customLocale="pt"
        />
      </StyledGridItem>
      <StyledGridItem xs={12} sm={3}>
        <Heading css={{ marginBottom: '$4' }} size="H4">
          With legend
        </Heading>
        <DatePicker
          value={singleDateLegend}
          onChange={setSingleDateLegend}
          customLocale="pt"
          legend={{ orientation: 'vertical' }}
          hightlightedDates={[
            {
              theme: 'gray',
              caption: 'First',
              dates: [new Date('03/20/2023'), new Date('03/10/2023'), new Date('03/24/2023')]
            },
            {
              theme: 'cyan',
              caption: 'Second',
              dates: [new Date('03/12/2023')]
            },
            {
              theme: 'black',
              caption: 'Third',
              dates: [new Date('03/17/2023')]
            }
          ]}
        />
      </StyledGridItem>
      <StyledGridItem xs={12} sm={3}>
        <Heading css={{ marginBottom: '$4' }} size="H4">
          With legend and CTA
        </Heading>
        <DatePicker
          value={singleDateLegendCTA}
          customLocale="pt"
          onChange={setSingleDateLegendCTA}
          onSave={setSingleDateLegendCTA}
          legend={{ orientation: 'vertical' }}
          callToActionSection
          hightlightedDates={[
            {
              theme: 'gray',
              caption: 'First',
              dates: [new Date('03/20/2023'), new Date('03/10/2023'), new Date('03/24/2023')]
            },
            {
              theme: 'cyan',
              caption: 'Second',
              dates: [new Date('03/12/2023')]
            },
            {
              theme: 'black',
              caption: 'Third',
              dates: [new Date('03/17/2023')]
            }
          ]}
        />
      </StyledGridItem>
      <StyledGridItem xs={12} sm={3} />
      <StyledGridItem xs={12} sm={3}>
        <Heading css={{ marginBottom: '$4' }} size="H4">
          With Label
        </Heading>
        <DatePicker
          label="Label"
          value={withLabel}
          customLocale="pt"
          onChange={setWithLabel}
          onSave={setWithLabel}
          legend={{ orientation: 'vertical' }}
          callToActionSection
          hightlightedDates={[
            {
              theme: 'gray',
              caption: 'First',
              dates: [new Date('03/20/2023'), new Date('03/10/2023'), new Date('03/24/2023')]
            },
            {
              theme: 'cyan',
              caption: 'Second',
              dates: [new Date('03/12/2023')]
            },
            {
              theme: 'black',
              caption: 'Third',
              dates: [new Date('03/17/2023')]
            }
          ]}
        />
      </StyledGridItem>
      <StyledGridItem xs={12} sm={3}>
        <Heading css={{ marginBottom: '$4' }} size="H4">
          With Error
        </Heading>
        <DatePicker
          label="Label"
          errorText="Error Text"
          hasError
          customLocale="pt"
          value={withError}
          onChange={setWithError}
          onSave={setWithError}
          legend={{ orientation: 'vertical' }}
          callToActionSection
          hightlightedDates={[
            {
              theme: 'gray',
              caption: 'First',
              dates: [new Date('03/20/2023'), new Date('03/10/2023'), new Date('03/24/2023')]
            },
            {
              theme: 'cyan',
              caption: 'Second',
              dates: [new Date('03/12/2023')]
            },
            {
              theme: 'black',
              caption: 'Third',
              dates: [new Date('03/17/2023')]
            }
          ]}
        />
      </StyledGridItem>
    </>
  );
};

export default DatePickerExamplePage;

export const StyledGridItem = styled(Grid.Item, {
  display: 'flex',
  flexDirection: 'column'
});
